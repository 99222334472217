<template>
    <div>
      <div v-if="jsonData">
        <json-item :item="jsonData" />
      </div>
    </div>
  </template>
  
  <script>
  import JsonItem from './JsonItem.vue';
  
  export default {
    name: 'JsonDisplay',
    components: {
      JsonItem
    },
  data() {
    return {
      jsonData: null
    };
  },
  methods: {
      async getJsonData() {
        try {
          const response = await fetch('https://cur.cab/m365bu/curcab/sharepoint');
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          return JSON.stringify(data);
        } catch (error) {
          console.error('There has been a problem with your fetch operation:', error);
          return null;
        }
      }
    },
    async created() {
      this.jsonData = await this.getJsonData();
    }
  };
  </script>
  
  <style scoped>
  .item {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
  }
  </style>
  