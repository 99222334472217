<template>
  <HomeButton />
  <br>
  <div class="dash-form">
    <h2>backup (fackup v2)</h2>
    <!--
    <form @submit.prevent="submitForm">
      <label for="dropdown">Tenant: </label>
      <select v-model="selectedOption">
        <option v-for="option in optionsTenant" :key="option.value" :value="option.value">
          {{ option.text }}
        </option>
      </select>
      <br>
      <label for="dropdown">Service: </label>
      

      <select v-model="selectedOption">
        <option v-for="option in optionsService" :key="option.value" :value="option.value">
          {{ option.text }}
        </option>
      </select>
      <br>
      <button type="submit" class="submit-button">Submit</button>
    </form>
    -->

    <div>
    <select v-model="selectedTenant">
      <option v-for="tenant in tenants" :key="tenant.id" :value="tenant">{{ tenant.name }}</option>
    </select>
    <select v-model="selectedService">
      <option v-for="service in services" :key="service.id" :value="service">{{ service.name }}</option>
    </select>
    <button @click="submitForm">Submit</button>
  </div>
  </div>

  <div class="content">
    <div>

      {{ currentService }}

      <ExchangeBackup v-if="currentService === 'exchange'" />
      <SharePointBackup v-if="currentService === 'sharepoint'" />
      <OneDriveBackup v-if="currentService === 'onedrive'" />
      <GroupsBackup v-if="currentService === 'groups'" />
      <BackupConfig v-if="currentService === 'config'" />
    </div>
  </div>
</template>

<script>
// components 
import { ref, computed } from 'vue';

import HomeButton from '@/components/HomeButton.vue';

import OneDriveBackup from '@/components/Backup/OneDriveBackup.vue';
import SharePointBackup from '@/components/Backup/SharePointBackup.vue';
import ExchangeBackup from '@/components/Backup/ExchangeBackup.vue';
import GroupsBackup from '@/components/Backup/GroupsBackup.vue';
import BackupConfig from '@/components/Backup/BackupConfig.vue';

// export default
export default {
  setup() {
    const selectedTenant = ref('krone');
    const selectedService = ref('exchange');
    const currentService = ref('exchange');

    const optionsTenant = ref([
      { value: 'krone', text: 'krone' },
      { value: '365Lab', text: '365Lab' },
      { value: 'curcab', text: 'curcab' },
    ]);

    const optionsService = ref([
      { value: 'config', text: 'config' },
      { value: 'sharepoint', text: 'sharepoint' },
      { value: 'exchange', text: 'exchange' },
      { value: 'onedrive', text: 'onedrive' },
      { value: 'groups', text: 'teams/groups' },
    ]);

    const submitForm = () => {
      console.log(JSON.stringify(selectedTenant.value));
      console.log(JSON.stringify(selectedService.value));
      console.log(JSON.stringify(currentService.value));
      currentService.value = selectedService.value;
      console.log(currentService);
    };

    const currentComponent = computed(() => {
      switch (currentService.value) {
        case 'sharepoint':
          return SharePointBackup;
        case 'exchange':
          return ExchangeBackup;
        case 'onedrive':
          return OneDriveBackup;
        case 'groups':
          return GroupsBackup;
        default:
          return null;
      }
    });

    return { selectedTenant: selectedTenant, optionsTenant: optionsTenant, optionsService: optionsService, submitForm, currentComponent };
  },
  components: {
    HomeButton,
    BackupConfig,
    OneDriveBackup,
    SharePointBackup,
    ExchangeBackup,
    GroupsBackup,
  },
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.dash-form {
  flex: 1;
  margin: 2%;
  padding: 2%;
  background-color: #111;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  margin: 2%;
  padding: 2%;
  background-color: #111;
  display: flex;
  flex-direction: column;
}

.submit-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 7px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #45a049;
}
</style>
