<template>
    <HomeButton />
    <br>
    <div class="dash-form">
        <h2>shortlinkydinky</h2>
        <form @submit.prevent="submitForm">
            <label for="textField">link:</label>
            <input type="text" v-model="selectedOption" id="textField" />
            <button type="submit" class="submit-button">create shortlink</button>
        </form>
        <div id="newlink">
            <v-if newlink.newlink> {{ newlink.newlink }}</v-if>
        </div>
    </div>
    <div>
        <json-item :item="links.data" />
    </div>


</template>

<script>
// components 
import HomeButton from '@/components/HomeButton.vue';
import JsonItem from '@/components/Shortlink/JsonItem.vue';

// imports
import { ref, onMounted } from 'vue';

// vars 

// export default
export default {

    setup() {
        const selectedOption = ref('');
        const newlink = ref('');
        const links = ref('');


        const submitForm = async () => {
            try {
                console.log(`Link: ${selectedOption.value}`);

                const response = await fetch('https://cur.cab/sl/createlink', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ url: selectedOption.value })
                });
                console.log("waiting now...")
                const data = await response.json();
                console.log(data)
                newlink.value = data;

            } catch (error) {
                console.error('Error:', error);
            }
        };
        const loadLinks = async () => {
            try {
                console.log("getting existing links...");

                const response = await fetch('https://cur.cab/sl/getlinks/1', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                console.log("bleepbloop... working...");
                const data = await response.json();
                console.log("bleepbloop.... done??:", data);

                links.value = data;
            } catch (error) {
                console.error('Error:', error);
            }
        };
        // eslint-disable-next-line no-undef
        onMounted(() => {
            loadLinks();
        });

        return {
            selectedOption,
            newlink,
            submitForm,
            loadLinks,
            links
        };
    },
    components: {
        HomeButton,
        JsonItem
    }
};
</script>

<style>
html,
body {
    height: 100%;
    margin: 0;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.dash-form {
    flex: 1;
    margin: 2%;
    padding: 2%;
    background-color: #111;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1;
    margin: 2%;
    padding: 2%;
    background-color: #111;
    display: flex;
    flex-direction: column;
}

.submit-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 7px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #45a049;
}
</style>
